.footer{
    background-color: black;
    margin-left: 20px;
    margin-right: 20px;
    height: 180px;
    margin-bottom: 20px;
}
.icons{
    color: pink;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 50px;
}
.icons a{
    color: pink;
}
.icons a:hover{
    color:red;
}
.icons div{
    height: 40px;
    width:40px;
    padding: 10px;
    margin: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.text{
    color: white;
    font-size: 18px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {

.footer{
    height: 150px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
}   
.text{
    display: flex;
    font-size: 10px;
}

}