.getintouch{
    height: 300px;
    background-color: #053659;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.line1, .line2{
    margin: 10px;
    padding-bottom: 10px;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
}
.line1{
    font-size: 50px;
    color: white;
}
.line2{
    font-size: 45px;
    color: #E7C23F;
}
.btn1{
    border-color: #E7C23F;
    margin-top: 15px;
    padding: 10px 20px;
    width: 200px;
    color: #00B4EC;
   font-size: 20px;
   font-weight: bold;
   font-style: italic;
   border-radius: 25px;
}
.btn1:hover{
    cursor: pointer;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .getintouch{
        height: 250px;
    }
    .line1, .line2{
        margin: 10px;
        padding-bottom: 5px;
    }
    .line1{
        font-size: 30px;
    }
    .line2{
        font-size: 25px;
    }
    .btn1{
     width: 150px;
       font-size: 16px;
    }
}