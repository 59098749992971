.thermalprofile{
    margin: 20px;
}
.tbppart1{
    background-color: rgb(224, 214, 111);
    display: flex;
    margin: 0 50px;
}
.tbpimage1, .tbptext1{
    flex: 1;
    height: 350px;
}
.tbpimage1{
    justify-content: flex-start;
    padding-right: 20px;
    flex: 0.7;
    
}
.tbpimage1 img{
    width: 100%;
    height: 100%; 
}
.tbptext1{
    display: flex;
    flex-direction: column;
}
.tbptext1 h1{
    text-align: left;
    padding-left: 40px;
    padding-top: 20px;
}
.tbptext1 p{
    font-size: 25px ;
    letter-spacing: 2px;
    word-spacing: 5px;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding: 0px 40px ;
}
.heading{
    margin: 20px;
    padding-top:  10px;
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
}

.tbpmould{
    max-width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    padding: 0px;
    padding-left: 100px;
    padding-right: 100px;
}
.tbpmouldimage{
    /* border-radius: 30px; */
    width: 300px;
    height: 350px;
    margin: 30px;
    /* box-shadow: 0px 3px 15px black; */
}
.tbpmouldimage:hover{
    box-shadow: 0px 3px 15 px rgba(0,0,0,0.5);
    transition: 0.3%ease-in;
    cursor: pointer;
}
.tbpmouldimage img{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-radius: 30px;
    /* width: 300px;
    height: 350px; */
    /* margin: 30px; */
    box-shadow: 0px 3px 15px black;
    width: 100%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.tbpmouldimage h3{
    margin-left: 20px;
    align-items: center;
}

@media (max-width: 768px) {
  .thermalprofile{
    margin: 10px;
}
.tbppart1{
    display: grid;
    margin: 0px;
}
.tbpimage1, .tbptext1{
    max-height: 450px;
}
.tbpimage1{
    height: 250px;
    justify-content: center;
    padding-right: 0px;
    width: 100%;
}
.tbptext1{
    display: flex;
    flex-direction: column;
}
.tbptext1 h1{
    text-align: center;
    padding: 0px;
    /* border: 1px solid black; */
}
.tbptext1 p{
    font-size: 20px ;
    /* border: 1px solid black; */
    letter-spacing: 0px;
    word-spacing: 0px;
    padding: 0px 20px;
    text-align: justify;
}
.heading{
    margin: 0px;
    padding: 10px;
    font-size: 25px;
}

.tbpmould{
    max-width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    padding: 0px;
}
.tbpmouldimage{
    height: 300px;
}
.tbpmouldimage img{
    height: 250px;
}
}